@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;500;600;700&display=swap');

:root {
  --home-bg-color: #2244f0;
  --home-fg-color: #96bfff;
  --home-accent-color: rgb(217, 251, 189);
  --about-bg-color: #100c4d;
  --about-fg-color: #c06f9e;
  --about-body-color: whitesmoke;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: auto;
}

html {
  height: 100%;
  width: 100%;
  background-color: black;
  scroll-behavior: auto;
}

body {
  font-family: 'Space Grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  font-weight:200;
  letter-spacing: -.05em;
  line-height: 1.1em;
}

p {
  font-family: 'Space Grotesk', serif;
  font-weight:400;
  font-size: 1.5rem;
}

a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

element {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

element::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@media(max-width:752px) {
  .Home {
    justify-content: center;
  }
}

.speciallink {
  color: #FFF;
  text-decoration: none;
}

.speciallink:hover {
  background-position: left;
}

.speciallink:focus {
  background-position: left;
}

.spacer {
  padding: 1rem;
  width: auto;
}

.spacer-sm {
  padding: 1rem;
  width: auto;
}
/* 
.pagetitle {
  font-size: calc(2rem + 6vmin);
  font-weight: 400;
}

.divider-sm5 {
  height: .5rem;
}

.expander {
  width: 0%;
  transition: 1s width;
}

.fader {
  opacity: 0;
  transition: 2s opacity;
}

.fade-in {
  opacity: 1;
}

.expand {
  width: 100%;
} */