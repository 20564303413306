.Home {
  /* background-color: rgb(30, 48, 128); */
  background-color: var(--home-bg-color);
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  display: flex;
  /* flex-direction: column;
  justify-content: flex-start; */
  font-size: calc(8px + 3vmin);
  color: var(--home-fg-color);
  margin: 0;
  overflow-x: hidden;
}

.Home-Image {
  display: none;
  margin: 0;
  padding : 0;
  position : absolute;
  margin-right : 0rem;
  margin-bottom : 0rem;
  height : auto;
  width : 100%;
}

.TextContainer {
  overflow-y: hidden;
}

.Home-Span {
  font-weight:200;
  color: var(--home-accent-color);
}
