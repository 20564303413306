/* BASE STYLES */
.Footer-Container {
  position: relative;
  display: flex;
  flex-direction:column;
  justify-content: center;
  width:100%;
  height: 20%;
  border-top-style: solid;
  border-top-width: 3px; 
}

.Footer-Content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.Footer-Link-Container {
  display: flex;
  flex-direction: row;
}

.Footer-Link {
  font-size: calc(20px + 2vmin);
  font-weight: 200;
  padding-right: 3rem;
  color:black;
  transition: 0.3s;
}

.Footer-Divider {
  font-size: calc(20px + 2vmin);
  font-weight: 200;
  padding-right: 3rem;
}

/* HOME PAGE COLORS */
.HOME-Footer-Container {
  color: var(--home-fg-color);
}

.HOME-Footer-Link {
  color: var(--home-fg-color);
}

.HOME-Footer-Link:hover {
  color: var(--home-accent-color);
}

.HOME-Footer-Divider {
  color : var(--home-fg-color);
}

/* ABOUT PAGE COLORS */
.ABOUT-Footer-Container {
  color :var(--about-fg-color);
}

.ABOUT-Footer-Link {
  color :var(--about-fg-color);
}

.ABOUT-Footer-Link:hover {
  color :var(--about-body-color);
}

.ABOUT-Footer-Divider {
  color :var(--about-fg-color);
}

@media(max-width:752px) {
  .Footer-Container {
    height: 50%;
    padding-bottom: 1rem;
    justify-content: center;
  }
  .Footer-Content {
    flex-direction: column;
  }
  .Footer-Link {
    padding-right: 0;
    font-size: 2rem;
  }
  .Footer-Divider {
    display: none
  }
}