.About {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  display: flex;
  font-size: calc(10px + 2vmin);
  margin: 0;
  overflow: hidden;
}

.About p {
  color : var(--about-body-color);
}

.About-Content {
  display : flex;
  flex-direction : row;
  justify-content: space-between;
  overflow-y: scroll;
  scrollbar-width: 0%;
}

.About-Content-Block {
  width: 50%;
}

.About-Img {
  min-width: 100%;
  min-height: 100%;
  flex-shrink: 0;
}

.About-Img-Container {
  position: absolute;
  width: 45%;
  overflow: hidden;
  right: 2rem;
  margin-left: 2rem;
  bottom: 10rem;
  top: 2rem;
}

.About-Color {
  background-color: var(--about-bg-color);
  color : var(--about-fg-color);
}

@media(max-width:752px) {
  .About h1 {
    font-kerning:auto;
    width: 100%;
  }

  .About-Content {
    flex-direction: column-reverse;
  }

  .About-Content-Block {
    width: 100%;
  }

  .About-Img-Container {
    position: relative;
    overflow:hidden;
    margin-left: 0;
    height: auto;
    width: 100%;
    margin-bottom: 2rem;
    top: auto;
    bottom: auto;
    right: auto;
    left: auto;
  }

  .About-Img {
    height: 50%;
  }
}
